import React from 'react'
import { gql } from '@apollo/client'
import {
  Button,
  Decorator,
  Heading,
  match,
  P,
  Section,
  ThemeProvider,
  tracking,
} from '@klickmarketing/react-components'
import { Box, ButtonBase, Container, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { navigate } from 'gatsby'
import {
  Link as I18nLink,
  Trans,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { IDX_FLOODLIGHT_TAGS } from '../../config'
import { DEFAULT_LOCALE } from '../../languages'
import { getSocialThumbPath } from '../common/utils'
import LayoutCopy from '../components/_articles/LayoutCopy'
import LayoutImage from '../components/_articles/LayoutImage'
import ContentfulImageWrapper from '../components/ContentfulImage/ContentfulImageWrapper'
import Layout from '../components/Layout/Layout'
import MediaTile from '../components/MediaTile/MediaTile'
import { useQueryLatestData } from '../hooks/useQueryLatestData'

const CollectionDetailsTemplate = ({ id }) => {
  const { language, t } = useI18next()
  const { latestData, error } = useQueryLatestData(GET_COLLECTION_DATA, {
    variables: { id, locale: language || DEFAULT_LOCALE },
  })

  React.useEffect(() => {
    if (!error) return
    navigate('/404', { replace: true })
  }, [error])

  React.useEffect(() => {
    if (!latestData) return
    // if there are no results retrieved send to a 404 page
    if (latestData.collectionCollection.items.length === 0) {
      window.location = '/404'
    }
  }, [latestData])

  React.useEffect(() => {
    tracking.track({ dcCode: IDX_FLOODLIGHT_TAGS.PAGE_LOAD_COLLECTION })
  }, [])

  if (!latestData)
    return (
      <>
        <Skeleton variant="rect" width="100%" height="60vh" />
        <Container maxWidth="lg">
          <Box mt={4}>
            <Skeleton variant="rect" width="100%" height="60vh" />
          </Box>
        </Container>
      </>
    )

  const collection = latestData.collectionCollection.items[0]
  const { title, description, image, featuredCount, backgroundImage } =
    collection
  const items = collection.itemsCollection.items
  const socialThumbPath = getSocialThumbPath(collection)
  const hasFeaturedCount = featuredCount && featuredCount < items.length
  const link = `/discover?collection=${title}`
  const seoProps = !collection
    ? { title: t(`Collection`, { ns: 'collections' }) }
    : {
        title: `${t('Collection', { ns: 'collections' })}: ${collection.title}`,
        description: collection.description,
        socialThumbPath,
      }
  console.log(items.length > 4, hasFeaturedCount && featuredCount > 4)
  return (
    <Layout seoProps={seoProps}>
      <ThemeProvider themeType="onPrimary">
        <StyledSection>
          <Box py={4}>
            <Container maxWidth="lg">
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={7}>
                  <Box>
                    <Heading component="h1" variant="h3">
                      {title}
                    </Heading>
                  </Box>
                  <WhiteDecorator />
                  <Box>
                    <P variant="blurb2">{description}</P>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <ImageContainer>
                    <Image
                      src={image.image.url}
                      alt={image.image.description}
                      focalPoint={image?.focalPoint?.focalPoint}
                      width={image.image.width}
                      height={image.image.height}
                    />
                  </ImageContainer>
                </Grid>
              </Grid>
            </Container>
          </Box>
          {backgroundImage && (
            <Backdrop>
              <ContentfulImageWrapper
                context="HERO"
                src={backgroundImage.url}
                alt={backgroundImage.description}
                role="presentation"
              />
            </Backdrop>
          )}
        </StyledSection>
        <ThemeProvider themeType="onWhite">
          <StyledSection name="Collection Items">
            {items.length > 4 || (hasFeaturedCount && featuredCount > 4) ? (
              <DenseListing
                hasFeaturedCount={hasFeaturedCount}
                featuredCount={featuredCount}
                items={items}
              />
            ) : (
              <LargeListing
                hasFeaturedCount={hasFeaturedCount}
                featuredCount={featuredCount}
                items={items}
              />
            )}
          </StyledSection>
        </ThemeProvider>
        {hasFeaturedCount && (
          <ThemeProvider themeType="onBlack">
            <Section textAlign="center">
              <Container maxWidth="md">
                <Heading variant="h3">
                  <Trans ns="collections">Looking for more?</Trans>
                </Heading>
                <P variant="blurb2">
                  <Trans ns="collections">
                    While the thoughts curated above might represent the latest
                    and most pertinent ideas in this area, they aren't the full
                    extent of our catalogue.
                  </Trans>
                </P>
                <Box pt={3}>
                  <Button
                    size="large"
                    color="primary"
                    component={I18nLink}
                    to={link}
                  >
                    <Trans ns="collections">View Full Collection</Trans>
                  </Button>
                </Box>
              </Container>
            </Section>
          </ThemeProvider>
        )}
      </ThemeProvider>
    </Layout>
  )
}

const LargeListing = ({ hasFeatured, featuredCount, items }) => {
  return hasFeatured
    ? [...Array(featuredCount).keys()].map((i, index) => {
        const { sys, __typename, ...rest } = items[index]
        return (
          <CollectionItem
            key={sys.id}
            index={index}
            type={__typename}
            {...rest}
          />
        )
      })
    : items.map(({ sys, __typename, ...rest }, index) => {
        return (
          <CollectionItem
            key={sys.id}
            index={index}
            type={__typename}
            {...rest}
          />
        )
      })
}

const DenseListing = ({ hasFeaturedCount, featuredCount, items }) => (
  <CardContainer maxWidth="lg">
    <Grid container direction="row" spacing={3}>
      {hasFeaturedCount
        ? [...Array(featuredCount).keys()].map((i, index) => {
            const item = items[index]
            return (
              <StyledGrid item xs={12} sm={6} md={4} key={item.sys.id}>
                <MediaTile {...item} />
              </StyledGrid>
            )
          })
        : items.map((item, index) => {
            return (
              <StyledGrid item xs={12} sm={6} md={4} key={item.sys.id}>
                <MediaTile {...item} />
              </StyledGrid>
            )
          })}
    </Grid>
  </CardContainer>
)

const GET_COLLECTION_DATA = gql`
  query GetCollectionDetailsData(
    $id: String!
    $preview: Boolean
    $locale: String!
  ) {
    collectionCollection(
      preview: $preview
      where: { slug: $id }
      limit: 1
      locale: $locale
    ) {
      items {
        title
        description
        backgroundImage {
          url
          description
        }
        image {
          focalPoint
          image {
            url
            description
            width
            height
          }
        }
        featuredCount
        itemsCollection(limit: 30) {
          items {
            __typename
            ... on ArticlePost {
              sys {
                id
              }
              title
              description
              slug
              heroImage {
                focalPoint
                image {
                  url
                  description
                  width
                  height
                }
              }
              personsCollection(limit: 4) {
                items {
                  sys {
                    id
                  }
                  name
                  credentials
                }
              }
            }
            ... on VideoPost {
              sys {
                id
              }
              title
              description
              publishDate
              slug
              personsCollection(limit: 4) {
                items {
                  sys {
                    id
                  }
                  name
                  credentials
                }
              }
              videoAsset {
                thumbnail {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

const CardContainer = styled(Container)`
  position: relative;
`
const StyledGrid = styled(Grid)`
  margin-bottom: 48px;
`

const CollectionItem = ({
  type,
  title,
  description,
  index,
  personsCollection,
  slug,
  ...rest
}) => {
  const { t } = useTranslation()
  const image =
    type === 'ArticlePost' ? rest.heroImage.image : rest.videoAsset.thumbnail
  const isOdd = index % 2 === 1
  const displayNames = {
    VideoPost: t('Video', { ns: 'collections' }),
    ArticlePost: t('Article', { ns: 'collections' }),
  }

  const isArticle = type === 'ArticlePost'
  const buttonText = isArticle
    ? t('Read Article', { ns: 'collections' })
    : t('Watch Video', { ns: 'collections' })
  const link = isArticle ? `/articles/${slug}` : `/videos/${slug}`
  const people = personsCollection.items

  return (
    <CollectionItemBox width="100%" $isGrey={Math.floor(index / 2) % 2 === 1}>
      <Container maxWidth={type === 'VIDEO' ? 'lg' : 'md'}>
        <CollectionTileWrapper to={link}>
          <Grid container spacing={4} direction={isOdd ? 'row-reverse' : 'row'}>
            <Grid item xs={12} sm={6}>
              <CenterColumn>
                <RippleContainer>
                  <CollectionImage
                    context="COLLECTION_ITEM"
                    image={image}
                    focalPoint={rest?.heroImage?.focalPoint}
                    $isArticle={isArticle}
                  />
                </RippleContainer>
              </CenterColumn>
            </Grid>
            <Grid item sm={6}>
              <Box mb={2}>
                <StyledP component="h4" variant="overline">
                  {displayNames[type]}
                </StyledP>
                <Link>
                  <ItemHeading component="h3" variant="h4">
                    {title}
                  </ItemHeading>
                </Link>
              </Box>
              <SpeakerText variant="blurb2">
                <b>{people.map(({ name }) => name).join(', ')}</b>
              </SpeakerText>
              <StyledDecorator />
              <Box>
                <LayoutCopy content={description} />
              </Box>
              <Box mt={4}>
                <ItemButton size="large" color="secondary">
                  {buttonText}
                </ItemButton>
              </Box>
            </Grid>
          </Grid>
        </CollectionTileWrapper>
      </Container>
    </CollectionItemBox>
  )
}

const StyledSection = styled(Section)`
  position: relative;
  padding: 0px;
  height: auto;
  margin-top: 58px;
  padding-bottom: 0px;

  ${match.isSM} {
    margin-top: 64px;
  }

  ${match.isMD} {
    margin-top: 84px;
  }
`

const CollectionTileWrapper = styled(I18nLink)`
  display: block;
  text-decoration: none;
`

const StyledP = styled(P)`
  margin: 0px;
`

const Link = styled.div`
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

const ItemHeading = styled(StyledP)`
  line-height: 1;
`

const SpeakerText = styled(StyledP)`
  margin-top: 8px;
`

const CollectionItemBox = styled(Box)`
  background: #fff;
  padding: 32px 0px;

  &:first-child {
    padding-top: 0;
  }

  ${match.isSM} {
    background: ${({ $isGrey }) => ($isGrey ? '#F2F2F2' : '#fff')};
    padding: 64px 0px;
  }
`

const Image = styled(ContentfulImageWrapper)`
  height: 100%;
  position: relative;
`

const CollectionImage = styled(LayoutImage)`
  width: 100%;
  padding-top: ${({ $isArticle }) => ($isArticle ? `156.25%` : `56.25%`)};

  filter: drop-shadow(-30px 30px 70px rgba(0, 0, 0, 0.2));
`

const CenterColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const RippleContainer = styled(ButtonBase)`
  width: 100%;
`

const ImageContainer = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
  filter: drop-shadow(-30px 30px 70px rgba(0, 0, 0, 0.2));

  * {
    height: 100%;
  }

  ${match.isSM} {
    width: 100%;
  }
`

const Backdrop = styled(Box)`
  width: 100%;
  height: 40%;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: -1;

  ${match.isMD} {
    height: 100%;
    width: 40%;
    top: 0px;
    right: 0px;
  }
`

const ItemButton = styled(Button)`
  width: 100%;

  ${match.isSM} {
    width: unset;
  }
`

const WhiteDecorator = styled(Decorator)`
  border-color: white;
  border-width: 2px;
`

const StyledDecorator = styled(Decorator)`
  margin: 16px 0px;
  border-width: 2px;
`

export default CollectionDetailsTemplate
