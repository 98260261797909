import React from 'react'
import { Router } from '@reach/router'
import { graphql } from 'gatsby'

import CollectionDetailsTemplate from '../../templates/CollectionDetailsTemplate'

const CollectionRouter = () => {
  return (
    <>
      <Router basepath="/collections">
        <CollectionDetailsTemplate path=":id" />
      </Router>
      <Router basepath="/:lang/collections">
        <CollectionDetailsTemplate path=":id" />
      </Router>
    </>
  )
}

export default CollectionRouter

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "collections"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
