import React from 'react'
import { Heading, P, TextLink, UL } from '@klickmarketing/react-components'
import { Box } from '@material-ui/core'
import Markdown from 'markdown-to-jsx'
import styled from 'styled-components'

const LayoutCopy = ({ content, $bgColor }) => {
  return <Markdown options={mdOptions}>{content}</Markdown>
}

const CustomUL = styled((props) => {
  const { children, ...rest } = props
  return (
    <Box mb={2}>
      <UL {...rest} variant="body1">
        {children}
      </UL>
    </Box>
  )
})`
  & > li > p {
    display: inline;
  }
`

const mdOptions = {
  forceBlock: true,
  overrides: {
    p: {
      component: P,
      props: {
        style: { wordBreak: 'break-word' },
      },
    },
    h1: {
      component: Heading,
      props: {
        variant: 'h2',
        style: { wordBreak: 'break-word' },
      },
    },
    h2: {
      component: Heading,
      props: {
        variant: 'h3',
        style: { wordBreak: 'break-word' },
      },
    },
    h3: {
      component: Heading,
      props: {
        variant: 'h4',
        style: {
          wordBreak: 'break-word',
          lineHeight: '0.9',
          marginTop: '0.7333em',
        },
      },
    },
    h4: {
      component: Heading,
      props: {
        variant: 'h5',
        style: {
          wordBreak: 'break-word',
          lineHeight: '0.9',
          marginTop: '0.7333em',
        },
      },
    },
    h5: {
      component: Heading,
      props: {
        variant: 'h6',
        style: {
          wordBreak: 'break-word',
          lineHeight: '0.9',
          marginTop: '0.7333em',
        },
      },
    },
    h6: {
      component: Heading,
      props: {
        variant: 'h6',
        style: {
          wordBreak: 'break-word',
          lineHeight: '0.9',
          marginTop: '0.7333em',
          fontSize: '0.8305rem',
        },
      },
    },
    ul: {
      component: CustomUL,
    },
    a: {
      component: TextLink,
    },
  },
}

export default LayoutCopy
